import { errorMessageHelpers, hasError } from '@/_helpers/funciones';

function data() {
    return {
      isSubmit: false,
//      isEdit: false,
    };
}
//methods
function errorMessage(field) {
    return errorMessageHelpers(this, field);
}
/**
 * nota: esta funcion siempre debe ser sobreescrita  en el componente 
 * debido a que cada form actual y contiene paramentros distintos 
 * mas se agrego para evitar error en los componentes
 */
function resetForm() {
    this.$v.$reset();
}

function alertProperties(properties = {}) {
    let defaultProperties = {
        text: `${this.$t('label.changeStatusQuestion')}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
    }
    return Object.assign(defaultProperties, properties);
}

function notifySuccess(properties = {}) {
    let notifyProperties = {
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: '¡'+this.$t('label.success')+'!',
        type: "success"
    }
    notifyProperties = Object.assign(notifyProperties, properties);
    this.$notify(notifyProperties);
}

function notifyError(properties = {}) {
    let notifyProperties = {
        group: 'container',
        title: '¡ Error !',
        text: '¡'+this.$t('label.errorsPleaseCheck')+'!',
        type: "error"
    }
    notifyProperties = Object.assign(notifyProperties, properties);
    this.$notify(notifyProperties);
}

function toggle(newVal) {
    if(!newVal)
      this.resetForm(); 
    this.modalActive = newVal;
}

function statusConfirmation(oldStatus, newStatus, method , register = ''){
    let status = oldStatus?1:0;
    if(newStatus === status)
      method();
    else{
      this.$swal.fire(this.alertProperties({
        text: `${this.$t('label.changeStatusQuestion')} ${register?' '+register:''}?`,
      })).then((result) => {
        if (result.isConfirmed) {
          method();
        }
      });
    }
}

// computed 
function statusOptions() {
    return [
        {
            value: 1,
            label: this.$t('label.ACTIVO')
        },
        {
            value: 0,
            label: this.$t('label.INACTIVO')
        }
    ]
}

function isSubmitValid(){
    return this.$v.$invalid || this.isSubmit;
}

export default {
    data,
    methods:{
        resetForm,
        alertProperties,
        errorMessage,
        toggle,
        statusConfirmation,
        hasError,
        notifyError,
        notifySuccess,
    },
    computed:{
        statusOptions,
        isSubmitValid,
    },
    watch:{
        modal: function(val) {
            this.toggle(val);
        },
        modalActive: function(val) {
            this.$emit('update:modal', val);
        }
    }
}